import React from 'react';
import { PageProps, graphql } from 'gatsby';
import FigmaCheatsheetIndex from './index';

const FigmaCheatsheetSplatPage = ({ location, data }: Pick<PageProps, 'location' | 'data'>) => {
  const fileId = location.pathname.split('/')[2];

  return <FigmaCheatsheetIndex fileId={fileId} data={data} />;
};

export default FigmaCheatsheetSplatPage;

export const query = graphql`
  query FigmaCheatsheetQuery {
    allComponentsJson {
      nodes {
        props {
          name
          type {
            raw
          }
        }
        displayName
      }
    }
  }
`;
